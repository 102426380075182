







































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  components: {
    MEditor: () => import("@/components/molecules/editor/m-editor.vue"),
  },
  setup(_, { root }) {
    const state = reactive({
      type: (root.$route.query.type as string) || "textWithFiles",
      participantGroups: [],
      loading: false,
      error: false as boolean | number,
    });

    const model = reactive({
      information: "",
      html: "",
      participantGroupsIds: [],
      files: [] as any,
    });

    const buttonDisabled = computed(() => {
      if (
        state.type === "textWithFiles" &&
        model.information &&
        model.files.length <= 5
      ) {
        return false;
      } else if (state.type === "html" && model.html) {
        return false;
      } else return true;
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/participant/group/select`)
        .then(({ data: { groups } }) => {
          state.participantGroups = groups;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchData);

    const rules = {
      required: [(v: string) => !!v || `${root.$t("layout.misc.required")}`],
      filesCount: [
        (v: any) =>
          !v ||
          v.length <= 5 ||
          `${root.$t(
            "panel.event.website.config.participantZone.add.filesLimit"
          )}`,
      ],
    };

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const formData = new FormData();
      if (model.information) formData.append("information", model.information);
      if (model.html) formData.append("html", model.html);
      if (state.type) formData.append("type", state.type);
      formData.append("fileType", "participantZoneDisplay");
      if (model.files && model.files.length) {
        for (const file of model.files) {
          formData.append("files", file);
        }
      }

      if (model.participantGroupsIds && model.participantGroupsIds.length) {
        for (const groupId of model.participantGroupsIds) {
          formData.append("participantGroupsIds[]", groupId);
        }
      }

      axiosInstance
        .post(
          `event/${root.$route.params.id}/participant-zone-display-data`,
          formData,
          {
            headers: {
              event: root.$route.params.id,
            },
          }
        )
        .then(() => {
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "success",
            message: root.$tc(
              "panel.event.website.config.participantZon.succes"
            ),
          });
          setTimeout(() => {
            root.$router.back();
          }, 500);
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        });
    };
    return { state, model, buttonDisabled, rules, onSubmit };
  },
});
